.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;

  h1 {
    font-size: 3rem;
    color: #ff0000;
  }

  p {
    margin: 20px 0;
    font-size: 1.2rem;
    color: #333;
  }

  .homeLink {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #2fff00;
    color: #000;
    text-decoration: none;
    border-radius: 5px;

    &:hover {
      background-color: #28a745;
      color: #fff;
    }
  }
}
