.footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  z-index: 40000;

  .container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .contact {
    h4 {
      margin-bottom: 10px;
    }
  }

  .social {
    h4 {
      margin-bottom: 10px;
    }
  }

  .phoneIcon {
    margin-left: 8px; // Adjust the spacing as needed
  }

  .socialIcons {
    display: flex;
    gap: 15px;
    margin-top: 15px; /* Space above icons */

    svg {
      width: 24px;
      height: 24px;
      fill: white; /* Default icon color */
      color: #fff;
      transition: fill 0.3s; /* Transition effect for hover */

      &:hover {
        fill: #007bff; /* Icon color on hover */
      }
    }
  }

  .footerBottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px; /* Smaller text for footer bottom */
  }
}
