@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

html,
body {
  height: 100vh; /* Ensure body takes full height */
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
}
