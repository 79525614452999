.layoutContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height of the viewport */
}

.mainContent {
  flex: 1; /* Fills the remaining space */
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px;

  nav {
    ul {
      list-style-type: none;
      display: flex;

      li {
        margin-right: 20px;

        a {
          color: #fff;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  // position: fixed;
  width: 100%;
  bottom: 0;
}
